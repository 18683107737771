var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-section"
  }, [this.$slots['containerImmersive'] ? _vm._t("containerImmersive") : _c('div', {
    staticClass: "container"
  }, [this.$slots['tit'] ? _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._t("tit")], 2), this.$slots['txt'] ? _c('p', {
    staticClass: "txt txt--lg line-height-135"
  }, [_vm._t("txt")], 2) : _vm._e()]) : _vm._e(), _vm._t("titContents"), _vm._t("default")], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }