<template>
    <client-page>
        <page-section class="page-section--first pb-0">
            <template slot="tit">{{ groupText }}</template>

            <v-card flat rounded="">
                <v-img cover :src="exhibition?.photo?.url" :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1200 / 500 : 200 / 100" />
            </v-card>
        </page-section>

        <page-section class="page-section--sm page-section--last">
            <v-row class="product-items">
                <shop-product-item v-for="item in items" :key="item._id" :value="item" />
            </v-row>

            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { EXHIBITION_GROUPS } from '@/assets/variables';

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductItem,
    },
    props: {
        _exhibition: { type: String, default: null },
    },
    data: () => ({
        exhibition: undefined,
        products: [],

        limit: 10,

        loading: false,
    }),
    computed: {
        groupText() {
            return EXHIBITION_GROUPS[this.exhibition?.group]?.text;
        },
        items() {
            return [...this.products];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil((this.exhibition?._products?.length || 0) / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            let _id = [...(this.exhibition?._products || [])].splice((this.page - 1) * this.limit || 0, this.page * this.limit);
            return { ...query, _id };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.exhibition = (await api.v1.shop.exhibitions.get({ _id: this._exhibition }))?.exhibition;
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { params } = this;
                var { products } = await api.v1.shop.products.gets({
                    params,
                });

                this.products = products;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
